import { useStaticQuery, graphql } from "gatsby";

export default function useSiteMetadata() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query ResumeQuery {
      site {
        siteMetadata {
          resumeUri
        }
      }
    }
  `);
  return siteMetadata;
}
