import React, { useCallback, useState } from "react";
import Layout from "../components/layout";
import { Document, Page } from "react-pdf";
import { useResizeDetector } from "react-resize-detector";
import useSiteMetadata from "../hooks/useSiteMetadata";
import { Spinner } from "react-bootstrap";

export default function ResumePage() {
  const { resumeUri } = useSiteMetadata();
  const { ref: resumeContainerRef, width } = useResizeDetector();
  const [numPages, setNumPages] = useState(0);
  const onLoadDocument = useCallback(
    ({ numPages }) => setNumPages(numPages),
    [setNumPages]
  );

  return (
    <Layout
      pageTitle="Torie Jee | Resume"
      fluid="lg"
      mainClassName="px-0 px-lg-3"
    >
      <Document
        file={resumeUri}
        onLoadSuccess={onLoadDocument}
        inputRef={resumeContainerRef}
        loading={<ResumeLoader>Loading PDF...</ResumeLoader>}
      >
        {Array.from(
          (function* () {
            for (let pageIndex = 0; pageIndex < numPages; pageIndex++) {
              yield (
                <Page
                  key={pageIndex}
                  pageIndex={pageIndex}
                  width={width}
                  loading={<ResumeLoader>Loading Page...</ResumeLoader>}
                />
              );
            }
          })()
        )}
      </Document>
    </Layout>
  );
}

function ResumeLoader({ children }) {
  return (
    <div className="d-flex justify-content-center">
      <Spinner
        animation="border"
        role="status"
        className="m-5"
        variant="primary"
      >
        <span className="sr-only">{children}</span>
      </Spinner>
    </div>
  );
}
